<template>
  <div class="form-wrap">
      <form class="login" @submit.prevent="signIn">
          <p class="login-register">
              还没有账号?
              <router-link class="router-link" :to="{name:'Register'}">注册</router-link>
          </p>
          <h2>登录到翟中豪的个人网站</h2>
          <div class="inputs">
                <div class="input">
                    <input type="text" placeholder="邮箱" v-model="email">
                    <email class="icon"/>
                </div>
                <div class="input">
                    <input type="password" placeholder="密码" v-model="password">
                    <password class="icon"/>
                </div>
                <div v-show="error" class="error">
                    {{this.errorMsg}}
                </div>
          </div>
          <router-link class="forgot-password" :to="{name:'ForgotPassword'}">忘记密码?</router-link>  
          <button>登录</button>
          <div class="angle"></div>        
      </form>
      <div class="background"></div>
  </div>
</template>

<script>
import email from "../assets/Icons/envelope-regular.svg"
import password from "../assets/Icons/lock-alt-solid.svg"
//import axios from 'axios'

export default {
    name:"Login",
    components:{
        email,
        password,
    },
    data(){
        return{
            email:null,
            password:null,
            error:null,
            errorMsg:"",
        };
    },
    methods:{
      async signIn(){
        if(this.email.trim() === "" || this.password.trim() === ""){
                this.error = true;
                this.errorMsg = "请输入邮箱和密码，谢谢!";
                return
        }
        //登录
        var that = this
        await this.$store.dispatch('login',{
          email:that.email,
          password:that.password,
        })
        .then(()=>{
            this.error = false;
            this.errorMsg = "";
            that.$store.commit('toggleShowLogin',{showLogin:false});
            that.$router.replace({name:'Home'})
        })
        .catch((err)=>{
            this.error = true;
            this.errorMsg = err.response.data.message;
            console.log(this.errorMsg)
        })
      }
    }
}
</script>

<style lang="scss">
.form-wrap {
  overflow: hidden;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-self: center;
  margin: 0 auto;
  width: 90%;
  @media (min-width: 900px) {
    width: 100%;
  }
  .login-register {
    margin-bottom: 32px;
    .router-link {
      color: #000;
    }
  }
  form {
    padding: 0 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    @media (min-width: 900px) {
      padding: 0 50px;
    }
    h2 {
      text-align: center;
      font-size: 32px;
      color: #303030;
      margin-bottom: 40px;
      @media (min-width: 900px) {
        font-size: 40px;
      }
    }
    .inputs {
      width: 100%;
      max-width: 350px;
      .input {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
        input {
          width: 100%;
          border: none;
          background-color: #f2f7f6;
          padding: 4px 4px 4px 30px;
          height: 50px;
          &:focus {
            outline: none;
          }
        }
        .icon {
          width: 12px;
          position: absolute;
          left: 6px;
        }
      }
    }
    .forgot-password {
      text-decoration: none;
      color: #000;
      cursor: pointer;
      font-size: 14px;
      margin: 16px 0 32px;
      border-bottom: 1px solid transparent;
      transition: 0.5s ease all;
      &:hover {
        border-color: #303030;
      }
    }
    .angle {
      display: none;
      position: absolute;
      background-color: #fff;
      transform: rotateZ(3deg);
      width: 60px;
      right: -30px;
      height: 101%;
      @media (min-width: 900px) {
        display: initial;
      }
    }
  }
  .background {
    display: none;
    flex: 2;
    background-size: cover;
    background-image: url("../assets/background.png");
    width: 100%;
    height: 100%;
    @media (min-width: 900px) {
      display: initial;
    }
  }
}
</style>